import React from "react";
import logo from "../logo.png"
import icon_img from "../icon.png"

const products = {
  "list": [
    {"name": "Appakara 5", "logo": "Appakara5.jpg",},
    {"name": "Appakara 7", "logo": "Appakara7.jpg",},
    {"name": "Appakara 12", "logo": "Appakara12.jpeg",},
    {"name": "Banali", "logo": "Banali.jpg",},
    {"name": "Appachatty", "logo": "Appachatti.jpeg",},
    {"name": "Pathirikkallu", "logo": "PathiriKallu.jpg",},
    {"name": "Kayil", "logo": "Kayil.jpg",},
    {"name": "Uruli", "logo": "Uruli-S.jpeg",},
    {"name": "Uruli", "logo": "Uruli.jpg",},
    {"name": "Uruli", "logo": "Uruli-L.jpeg",},
    {"name": "Chattakam", "logo": "Chattukam.jpeg",},
    {"name": "Warp", "logo": "Varp-S.jpg",},
    {"name": "Warp", "logo": "Varp-M.jpg",},
    {"name": "Warp", "logo": "Varp-L.jpg",},
  ],
  "gallary": [
    {"image": "Appakara5.jpg",},
    {"image": "Appakara7.jpg",},
    {"image": "Appakara12.jpeg",},
    {"image": "Banali.jpg",},
    {"image": "Appachatti.jpeg",},
    {"image": "PathiriKallu.jpg",},
    {"image": "Kayil.jpg",},
    {"image": "Uruli-S.jpeg",},
    {"image": "Uruli.jpg",},
    {"image": "Uruli-L.jpeg",},
    {"image": "Chattukam.jpeg",},
    {"image": "Varp-S.jpg",},
    {"image": "Varp-M.jpg",},
    {"image": "Varp-L.jpg",},
  ]
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.products = products.list;
    this.gallary = products.gallary;
    this.state = { color: "blue" };
    this.listItems = this.products.map((product, index) =>
      <div className="col-lg-4 col-md-6" key={index}>
        <div className="item">
          <img src={require(`../img/products/${product.logo}`)} alt="item 1" className="img-fluid" />
          <div className="details">
            <h3>{product.name}</h3>
            <div className="social">
              <a href="javascript:void(0);"><i className="fa fa-twitter"></i></a>
              &nbsp;&nbsp;<a href="javascript:void(0);"><i className="fa fa-facebook"></i></a>
              &nbsp;&nbsp;<a href="javascript:void(0);"><i className="fa fa-google-plus"></i></a>
              &nbsp;&nbsp;<a href="javascript:void(0);"><i className="fa fa-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
    );

    this.gallary = this.gallary.map((product, index) =>
      <a href={require(`../img/products/${product.image}`)} key={index} className="venobox" data-gall="gallery-carousel">
        <img src={require(`../img/products/${product.image}`)} alt="" />
      </a>
    );
  }

  render() {
    return (
      <div>
        <header id="header">
          <div className="container">
            <div id="logo" className="pull-left">
              <a href="#intro" className="scrollto">
                <img src={logo} alt="" title="" />
              </a>
            </div>
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <li className="menu-active"><a href="#intro">Home</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#items">Products</a></li>
                <li><a href="#contact">Contact Us</a></li>
                <li><a href="#gallery">Gallery</a></li>
                {/* <li className="buy-tickets"><a href="#cart">&nbsp;</a></li> */}
              </ul>
            </nav>
          </div>
        </header>
        <section id="intro">
          <div className="intro-container wow fadeIn">
          <h1 className="mb-4 pb-0"><img className="text-center" src={icon_img} alt="" title="" /><br />
          <span>Loyal <br /></span></h1>
          <h3><span className="whitebg">Brand Moulding Aluminium <br />Anodised and Non Anodised Items</span></h3>
          </div>
        </section>

        <section id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>About Us</h2>
                <p>The Loyal group is completely licensed since 1997. 
                Through successful undertaking, we became the very first company to manufacture and 
                supply Indalium products across South India. We manufacture and supply Indalium and Aluminium 
                vessels and utensils. Ensuring the best quality ever lead us to success so far.
                </p>
              </div>
              <div className="col-lg-12">
                <h3>The Founder</h3>
                <p>The very courageous entrepreneur Mr. <b className="redbg">K.M ABDUL KHADER</b> is the founder 
                  of the Loyal group of companies which has now become the leading manufacturer 
                  of Indalium products in Kerala.</p>
              </div>
              <div className="col-lg-12">
                <h3>Manufacturing Unit</h3>
                <p>A manufacturing unit started at Chemparakey, Aluva. 
                  Loyalty and sincerity of workers have helped the group to step up till now.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="items" className="wow fadeInUp">
          <div className="container">
            <div className="section-header">
              <h2>Products</h2>
              <p>Here are some of our products</p>
            </div>
            <div className="row">
              {this.listItems}
            </div>
          </div>
        </section>

        <section id="contact" className="section-bg wow fadeInUp">
          <div className="container">
            <div className="section-header">
              <h2>Contact Us</h2>
              <p>AL-AMEEN ALUMINIUM INDUSTRIES</p>
            </div>
            <div className="row contact-info">
              <div className="col-md-4">
                <div className="contact-address">
                  <i className="ion-ios-location-outline"></i>
                  <h3>Address</h3>
                  <address>
                    Door No: V.P.VIII/164 B, S. Vazhakulam P.O, <br />
                    Chemparakey, Aluva - Kerala
                  </address>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-phone">
                  <i className="ion-ios-telephone-outline"></i>
                  <h3>Phone Number</h3>
                  <p><a href="tel:+919961736647">+91 9961736647</a></p>
                  <p><a href="tel:+919744646433">+91 9744646433</a></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-email">
                  <i className="ion-ios-email-outline"></i>
                  <h3>Email</h3>
                  <p><a href="mailto:shemeerloyal@gmail.com">shemeerloyal@gmail.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="gallery" className="wow fadeInUp">
          <div className="container">
            <div className="section-header">
              <h2>Gallery</h2>
              <p>Check our gallery from the recent events</p>
            </div>
          </div>
          
          <div className="owl-carousel gallery-carousel">
            {this.gallary}
          </div>
        </section>

        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-info">
                  <img src={logo} alt="TheEvenet" />
                    <p>Brand Moulding Aluminium <br />Anodised and Non Anodised Items.</p>
                </div>
                <div className="col-lg-6 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><i className="fa fa-angle-right"></i> <a href="#intro">Home</a></li>
                    <li><i className="fa fa-angle-right"></i> <a href="#about">About Us</a></li>
                    <li><i className="fa fa-angle-right"></i> <a href="#items">Products</a></li>
                    <li><i className="fa fa-angle-right"></i> <a href="#contact">Contact Us</a></li>
                    <li><i className="fa fa-angle-right"></i> <a href="#gallery">Gallery</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h4>Contact Us</h4>
                  <p>
                    AL-AMEEN ALUMINIUM INDUSTRIES <br />
                    S. Vazhakulam P.O, Aluva, Kerala <br />
                    <strong>Phone: </strong>+91 9961736647, <br />+91 9744646433<br />
                    <strong>Email: </strong> shemeerloyal@gmail.com<br />
                  </p>
                  <div className="social-links">
                    <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
                    <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                    <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
                    <a href="#" className="google-plus"><i className="fa fa-google-plus"></i></a>
                    <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              &copy; Copyright <strong>Loyal</strong>. All Rights Reserved
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Index;